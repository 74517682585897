
@media (min-width: 1024px) {




.logo {
    width:100px;
}

.blackcolor {
    background-color: black;
    width: 100%;
  
   
    
}

.logo2 {
    width:100px;
    margin-top:-810px;
    padding-left:700px;
    position: absolute;
}

.logo3 {
    width:100px;
    margin-top:400px;
    padding-left:70px;
    position: absolute;
}

.logo4 {
    width:100px;
    margin-top:-800px;
    padding-left:620px;
    position: absolute;
}

.invest {
    width:400px;
    margin-top:-810px;
    padding-left:820px;
    position: absolute
}

.trade {
    width:400px;
    padding-top:408px;
    padding-left:180px;
    position: absolute
}

.earn {
    width:400px;
    margin-top: -810px;
    padding-left:740px;
    position: absolute  
}

.investdescription {
    font-family: 'Goldman', cursive;
    font-size: 30px;
    margin-top:-650px;
    padding-left:700px;
    position: absolute;
    color: white
}

.tradedescription {
    font-family: 'Goldman', cursive;
    font-size:90px;
    margin-top:400px;
    margin-left:-500px;
    position: absolute;
    transform: scale( .4);
    color: white
}


.earndescription {
    font-family: 'Goldman', cursive;
    font-size:35px;
    margin-top:-650px;
    margin-left:600px;
    position: absolute;
    color: white
    
}


.fan {
    width:200px;
    padding-top: -50px;
    position: absolute
}


.momentum {
    width:600px;
    padding-top:-50px;
    padding-left:210px;
    position: absolute
    
}

.fanmomentumgradient {
    width:800px;
    padding-top: -50px;
    position: absolute
}

.fanmomentumgif {
    width:260px;
    margin-top: 100px;
    margin-left: 370px;
    position: absolute
}

.gradient1 {
    width:1200px;
    padding-top: 100px;
    padding-left: 40px;
    position: absolute
}

.parent1 {
    position: relative;
    top: 0;
    left: 0;
  }
  
  .image1 {
    position: relative;
    width:600px;
    height:1000px;
    padding-top: 250px;
    padding-left: 40px;
    /* top: 0;
    left: 0; */

    /* border: 1px solid #000000; */
  }
  
  .image2 {
    position: absolute;
    padding-top: 360px;
    margin-left: -580px;
    width: 280px;

  }

  .image3 {
    position: absolute;
    padding-top: 600px;
    margin-left: -300px;
    width: 290px;
 
  }

  .image4 {
    position: absolute;
    padding-top: 750px;
    margin-left: -580px;
    height: 360px;
    width: 260px;
  }

  .image5 {
    position: absolute;
    padding-top: 750px;
    margin-left: -300px;
    height: 360px;
    width: 270px;
   
  }

  .parent2 {
    position: relative;
    top: 0;
    left: 0;
  }
  
  .image1trade {
    position: relative;
    width:500px;
    height:1000px;
    padding-top: 200px;
    padding-left: 750px;
    /* border: 1px solid #000000; */
  }
  
  .image2trade {
    position: absolute;
    width:350px;
    margin-top:220px;
    margin-left: -500px;
   
  }

  .image3trade {
    position: absolute;
    width:400px;
    margin-top:450px;
    margin-left: -400px;
   
  }

  .image4trade {
    position: absolute;
    width:350px;
    margin-top:680px;
    margin-left: -500px;
   
  }

  .image5trade {
    position: absolute;
    width:400px;
    margin-top:900px;
    margin-left: -400px;
   
  }

 

  .parent3 {
    position: relative;
    top: 0;
    left: 0;
  }
  

  .image1earn {
    position: relative;
    width:560px;
    height:1000px;
    padding-top: 100px;
    padding-left: 20px;
    /* border: 1px solid #000000; */
  }
  
  .image2earn {
    position: absolute;
    width:250px;
    margin-top:120px;
    margin-left: -550px;
   
  }

  .image3earn {
    position: absolute;
    width:250px;
    margin-top:600px;
    margin-left: -420px;
   
  }

  .image4earn {
    position: absolute;
    width:255px;
    margin-top:112px;
    margin-left: -300px;
  }
/* 
  .image5earn {
    position: absolute;
    width:400px;
    margin-top:900px;
    margin-left: -400px;
   
  } */

 

  .hometitle {
    font-family: 'Goldman', cursive;
    font-size: 90px;
    padding-top: 500px;
    padding-left: 40px;
    background: #121FCF;
background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}

.hometitle2 {
    font-family: 'Goldman', cursive;
    font-size: 70px;
    /* padding-top: 600px; */
    padding-left: 160px;
    background: #121FCF;
background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    
}

.leaguelogos {
    width:1200px;
    margin-top:300px;
    padding-left:20px;
    padding-bottom:300px;
    /* position: absolute; */
}



}

@media (min-width: 768px) and (max-width: 1023px) {

}

@media (max-width: 767px) {

  
    .logo {
        width:500px;
        padding-left:400px;
    }

  

    .blackcolor {
        background-color: black;
        width: 1400px;
       
    }

    .logo2 {
        width:400px;
        margin-top:250px;
        padding-left:510px;
        position: absolute;
    }

    .logo3 {
        width:400px;
        margin-top:200px;
        padding-left:510px;
        position: absolute;
    }

    .logo4 {
        width:400px;
        margin-top:260px;
        padding-left:510px;
        position: absolute;
    }

    
    
    .invest {
        width:800px;
        margin-top:800px;
        padding-left:320px;
        position: absolute
    }
    
    .investdescription {
        font-family: 'Goldman', cursive;
        font-size: 90px;
        margin-top:1100px;
        margin-left: 100px;
        /* padding-right:150px; */
        /* position: absolute; */
        color: white
        
    }

    
   
    .trade {
        width:800px;
        padding-top:800px;
        padding-left:300px;
        position: absolute
    }

    .tradedescription {
        font-family: 'Goldman', cursive;
        font-size:100px;
        margin-top:1100px;
        margin-left:150px;
        /* position: absolute; */
        /* transform: scale( .4) */
        color: white
    }

    .earn {
        width:800px;
        margin-top: 850px;
        padding-left:320px;
        position: absolute  
    }

    .earndescription {
        font-family: 'Goldman', cursive;
        font-size:110px;
        margin-top:1200px;
        margin-left:100px;
        /* position: absolute;
        transform: scale( 1.2); */
        color: white
        
    }

    
    .fan {
        width:500px;
        padding-top: 20px;
        padding-left: 400px;
        /* position: absolute */
    }
    
    .momentum {
        width:1200px;
        padding-top: 80px;
        padding-left: 25px;
        /* position: absolute */
    }

    /* .fanmomentumgradient {
        width:1200px;
        padding-top: 50px;
        position: absolute;
        padding-left: 25px; 
    } */

    .fanmomentumgradient {
        width:1200px;
        padding-top: 700px;
        position: absolute;
        margin-left: -800px; 
    }

    /* .mobileappbackground {
        width:1200px;
        padding-top: 2px;
        padding-left: 40px;
        position: absolute
    } */

    .fanmomentumgif {
        width:1000px;
        padding-top: 1140px;
        margin-left: -700px;
        position: absolute
    }

    .hometitle {
        font-family: 'Goldman', cursive;
        font-size: 150px;
        padding-top: 3100px;
        margin-left: 150px;
        padding-right:50px;
        background: #121FCF;
background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
       
        
    }
    
    .hometitle2 {
        font-family: 'Goldman', cursive;
        font-size: 150px;
        padding-top: 10px;
        padding-left: 150px;
        padding-right:50px;
        background: #121FCF;
        background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
 
    }

    .parent1 {
        position: relative;
        top: 0;
        left: 0;
      }
      
      .image1 {
        position: relative;
        width:1150px;
        height:4000px;
        margin-top: 400px;
        padding-left: 130px;
        /* top: 0;
        left: 0; */
    
        /* border: 1px solid #000000; */
      }
      
      .image2 {
        position: absolute;
        width:1000px;
        margin-top: 550px;
        margin-left: -1060px;
      }
    
      .image3 {
        position: absolute;
        width:1000px;
        margin-top: 2450px;
        margin-left: -1060px;
      }
    
      .image4 {
        position: absolute;
        margin-top: 2850px;
        margin-left: -1000px;
        height: 1060px;
        width: 860px;
      }
    
      .image5 {
        position: absolute;
        margin-top: 4050px;
        margin-left: -1000px;
        height: 1160px;
        width: 860px;
       
      }
    

      .parent2 {
        position: relative;
        top: 0;
        left: 0;
      }
      
      .image1trade {
        position: relative;
        width:1130px;
        height:2300px;
        padding-top: 300px;
        padding-left: 140px;
        /* border: 1px solid #000000; */
      }


      .image2trade {
        position: absolute;
        width:850px;
        margin-top: 400px;
        margin-left: -1100px;
       
      }
    
      .image3trade {
        position: absolute;
        width:1000px;
        margin-top: 1000px;
        margin-left: -1000px;
       
      }
    
      .image4trade {
        position: absolute;
        width:850px;
        margin-top: 1500px;
        margin-left: -1100px;
       
      }
    
      .image5trade {
        position: absolute;
        width:900px;
        margin-top: 2000px;
        margin-left: -1000px;
       
      } 

      .parent3 {
        position: relative;
        top: 0;
        left: 0;
      }
      
    
      .image1earn {
        position: relative;
        width:1140px;
        height:6200px;
        padding-top: 200px;
        padding-left: 140px;
        /* border: 1px solid #000000; */
      }
      
      .image2earn {
        position: absolute;
        width:1000px;
        margin-top: 350px;
        margin-left: -1050px;
       
      }
    
      .image3earn {
        position: absolute;
        width:1000px;
        margin-top: 2350px;
        margin-left: -1050px;
       
      }
    
      .image4earn {
        position: absolute;
        width:1000px;
        margin-top: 4400px;
        margin-left: -1050px;
       
      }
    
      .leaguelogos {
        width:1200px;
        margin-top:800px;
        padding-left:100px;
        padding-bottom: 1400px;
        /* position: absolute; */
    }


  

}